import React from 'react'; 
import './App.css';  
import Redoc from './components/Redoc';
import MarkdownRenderer from './components/MarkdownRenderer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import GoogleForm from './components/GoogleForm';

function App() {
  return (
    <div className="App"> 
      <Router>
        <Switch>
          <Route exact path="/">
            <MarkdownRenderer path={'/README.md'} />
          </Route>
          <Route path="/documentation" exact>
            <Redoc />
          </Route>
          <Route path="/requestaccess" exact>
            <GoogleForm />
          </Route>
        </Switch>
      </Router> 
    </div>
  );
}

export default App;
