import React, {Component} from 'react'; 
import { RedocStandalone } from 'redoc'; 
import './Redoc.css'

class Redoc extends Component {

    componentDidMount() {
        
    }

    render() {
        return (
            <RedocStandalone
                specUrl="endpoints.yaml"
                options={{
                    nativeScrollbars: false,
                    hideDownloadButton: true,
                    noAutoAuth: true,
                    pathInMiddlePanel: false,
                    // hideHostname: true,
                    expandDefaultServerVariables: true,
                    onlyRequiredInSamples: false,
                    hideLoading: true,
                    theme: { 
                        colors: { primary: { main: '#474e54' } },
                        rightPanel: {
                            backgroundColor: '#00142d', 
                            textColor: '#FFF',
                          },
                    },
                }}off
            />
        );
    }
}
 

export default Redoc;