import React, {Component} from 'react';
import './GoogleForm.css'

class GoogleForm extends Component {

    componentDidMount() {

    }

    render() {
        return (

    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlnm07stk3Niuta_J_2kBegf2ScDasvWWb3DNVC_lOC6o9vw/viewform?usp=sf_link"
        width='100%'
        height='100%'>

    </iframe>
        );
    }
}


export default GoogleForm;