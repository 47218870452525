import React, {Component, PropTypes} from 'react';  
import fs from 'fs';
import Markdown from 'react-markdown'
import toc from 'remark-toc'
import 'github-markdown-css'  
import './MarkdownRenderer.css'

class MarkdownHeading extends Component {

    constructor(props) {
        super(props)
    }

    flatten = (text, child) => {
        return typeof child === 'string'
          ? text + child
          : React.Children.toArray(child.props.children).reduce(this.flatten, text)
      }

    render() {
        var children = React.Children.toArray(this.props.children)
        var text = children.reduce(this.flatten, '')
        var slug = text.toLowerCase().replace(/\W/g, '-')
        return React.createElement('h' + this.props.level, {id: slug}, this.props.children)
    }
}

class MarkdownRenderer extends Component {

    constructor(props){
        super(props); 
        this.state = {
            markdown: ""
        }
        
        if (props.path) this.getFromFile(props.path)
    }

    getFromFile = (URI) => {
        fetch(URI)
        .then((r) => r.text())
        .then(md  => {
            this.setState({markdown: md})
        })  
    }

    componentDidMount() { 
    }

   
    render() {
        return (
            <div> 
                <div className='markdown-body'>
                    <Markdown 
                        source={this.state.markdown} 
                        escapeHtml={false} 
                        sourcePos={false}
                        includeNodeIndex={true}
                        plugins={[toc]}
                        renderers={{heading: MarkdownHeading}}
                    /> 
                </div>
            </div>
        );
    }
} 
  

export default MarkdownRenderer;